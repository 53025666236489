@import "../scss/variables";

.apartment-view {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $color-white;
    z-index: 110;

    &__left {
        flex: 0 1 (4/3) * 100vh;
        display: block;
        overflow: hidden;
        position: relative;

        &-iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

        &-buttons {
            width: 7rem;
            height: 28rem;
            position: absolute;
            right: 0;
            z-index: 2;
            top: 8rem;
            background-color: $color-white;
            box-shadow: 0 0 2rem 0 rgba(black, 0.3);
            display: flex;
            flex-direction: column;

            @media (min-width: 1400px) {
                width: 10rem;
                height: 40rem;
            }
        }

        &-button {
            width: 100%;
            height: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $color-white;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            color: black;

            &-icon {
                @include ff-icon;
                font-size: 3rem;

                @media (min-width: 1700px) {
                    font-size: 4rem;
                }
            }

            &-logo {
                width: 2.5rem;
                height: 2.5rem;
                position: relative;

                @media (min-width: 1700px) {
                    width: 4rem;
                    height: 4rem;
                }

                &-img {
                    position: absolute;
                    height: 100%;
                    transition: opacity 0.2s ease-in-out;

                    &.light {
                        opacity: 0;
                    }
                }
            }

            &-label {
                text-align: center;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 0.78rem;
                padding-top: 0.2rem;

                @media (min-width: 1400px) {
                    font-size: 1.1rem;
                }
            }

            &:hover {
                background-color: $color-grey-2;
            }

            &.is-active {
                background-color: $color-primary;

                .apartment-view {
                    &__left {
                        &-button {
                            &-logo {
                                &-img {
                                    &.dark {
                                        opacity: 0;
                                    }
                                    &.light {
                                        opacity: 1;
                                    }
                                }
                            }
                            &-icon {
                                color: $color-white;
                            }
                            &-label {
                                color: $color-white;
                            }
                        }
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-7;
            }
        }
    }

    &__right {
        flex: 1 0 40rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 2;
        box-shadow: 0 0 2rem 0 rgba(black, 0.5);

        &-content {
            width: 100%;
            height: 100%;
            max-width: 70rem;
            display: flex;
            padding: 0 2rem;
            align-items: stretch;
            flex-direction: column;
        }
    }

    &__header {
        display: flex;
        width: 100%;
        background-color: $color-grey-1;
        margin: 2rem 0;
        height: 6rem;
        align-items: center;

        &-code {
            font-size: 4rem;
            font-weight: bold;
            padding: 0 1rem;
            color: $color-grey-7;
        }

        &-label {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: bold;
            color: $color-grey-6;
            padding: 0 1rem;
            flex: 1 0 10rem;
        }

        &-icon {
            padding: 1rem;
            @include ff-icon;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            color: $color-grey-7;
            cursor: pointer;
        }
    }

    &__info-box {
        display: flex;
        flex-direction: column;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.3);

        &-tiles {
            display: flex;
            width: 100%;
            padding: 1rem;
            align-items: stretch;
        }

        &-tile {
            flex: 1 0 10rem;
            text-align: center;
            padding: 1rem;
            font-size: 1.6rem;
            color: $color-grey-6;

            b {
                color: $color-grey-7;
            }

            &:not(:last-child) {
                border-right: 1px solid $color-grey-2;
            }
        }

        &-lines {
            padding: 1rem 2rem 2rem;
            display: flex;
            flex-direction: column;
        }

        &-line {
            font-size: 1.4rem;
            color: $color-grey-7;
            position: relative;
            display: flex;
            align-items: center;
            padding: 1rem 1rem 1rem 1rem;

            &::before {
                content: "";
                // position: absolute;
                // left: 0;
                // top: 1.7rem;
                height: 0.8rem;
                margin-right: 1rem;
                width: 0.8rem;
                background-color: $color-primary;
            }
        }
    }

    &__buttons {
        display: flex;
        padding: 3rem 2rem 3rem;
        width: 100%;
        justify-content: center;
    }

    &__tabview {
        display: flex;
        flex-direction: column;
        flex: 1 0 10rem;
        overflow: auto;

        &-nav {
            padding: 2rem 2rem;
            display: flex;

            &-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid $color-grey-1;
            }

            &-link {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-grey-3;
                padding: 1rem 0;
                margin-bottom: -2px;
                position: relative;

                &.active {
                    color: $color-grey-7;
                    border-bottom: 2px solid $color-grey-7;

                    &::after {
                        content: "";
                        width: 0.8rem;
                        height: 0.8rem;
                        position: absolute;
                        bottom: -2px;
                        margin-left: -0.4rem;
                        left: 50%;
                        border-bottom: 2px solid $color-grey-7;
                        border-right: 2px solid $color-grey-7;
                        background-color: $color-white;
                        transform-origin: 0% 100%;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        &-page {
            display: flex;
            flex-direction: column;
            padding: 2rem;
        }
    }

    &__scrollable {
        flex: 1 0 10rem;
        overflow: auto;
        margin-top: 2rem;
        // margin-bottom: 2rem;
    }

    &__floor-layout {
        width: 100%;

        &-img {
            width: 100%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        flex: 1 0 50%;

        &-availability {
            font-size: 2.4rem;
            padding: 0 1rem;
            font-weight: bold;
            color: $color-grey-7;
        }

        &-label {
            color: $color-grey-5;
            font-size: 1.6rem;
        }

        &-value {
            font-weight: bold;
            color: $color-grey-7;
            font-size: 1.6rem;
        }

        &-check {
            padding: 0 2rem 0.5rem 2rem;
            position: relative;
            font-size: 1.4rem;
            color: $color-grey-5;

            &::before {
                content: "check";
                @include ff-icon;
                position: absolute;
                left: 0;
                font-size: 1.4rem;
                color: $color-secondary;
            }
        }

        &-row {
            width: 100%;
            display: flex;
            padding: 2rem 1rem;
            width: 100%;

            &.column {
                flex-direction: column;
            }

            &-split {
                flex: 1 0 50%;

                &:first-child {
                    padding-right: 1rem;
                }
                &:last-child {
                    padding-left: 1rem;
                }
            }
        }

        &-areas {
            display: flex;
            flex-direction: column;
            width: 100%;

            &-title {
                color: $color-grey-7;
                font-size: 1.6rem;
                padding-bottom: 1rem;
            }
        }

        &-area {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            font-size: 1.6rem;
            max-width: 40rem;

            &-label {
                color: $color-grey-7;
                flex: 1 0 60%;
            }

            &-value {
                font-weight: bold;
                flex: 1 0 40%;
                text-align: right;
                padding: 0.3rem 0;
                color: $color-grey-7;

                sup {
                    font-size: 1rem;
                }
            }
        }

        &-image {
            width: 100%;
        }

        &.right {
            text-align: right;
        }

        &.big {
            .apartment-view__info {
                &-value {
                    font-size: 3.5rem;

                    sup {
                        font-size: 2rem;
                    }
                }
            }
        }
    }

    &__info-group {
        display: flex;
        background-color: $color-white;
        box-shadow: 0 0 15px 0px rgba(black, 0.3);
        width: 100%;

        &-value {
            padding: 2rem 0;
            flex: 1 0 5rem;
            text-align: center;
            font-size: 1.6rem;

            &:not(:last-child) {
                border-right: 1px solid $color-grey-1;
            }
        }
    }
}
