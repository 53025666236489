@import "../scss/variables";

.apartment-filter {
    width: 100%;
    max-width: $dim-breakpoint-lg;
    display: flex;

    &__column {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &-spacer {
            width: 100%;
            height: 1rem;
        }
    }
}
