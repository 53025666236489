@import "../scss/variables";

.apartments {
    width: 100%;
    height: 100%;
    display: flex;

    &__aside {
        flex: 1 0 40rem;
        box-shadow: 0 0 3rem 0px rgba(black, 0.3);
        background-color: $color-white;
        z-index: 2;
        display: flex;
        justify-content: center;
        position: relative;

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 70rem;
            padding: 2rem 0 0;
        }

        &-padding {
            padding: 0 2rem;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-header {
            width: 100%;
            height: 6rem;
            background-color: $color-grey-1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-title {
                padding: 0 1rem;
                font-size: 3rem;
                text-transform: uppercase;
                color: $color-grey-7;
                font-weight: bold;
            }

            &-menu {
                @include ff-icon;
                font-size: 4rem;
                color: $color-grey-7;
                padding: 0 1rem;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        &-building {
            padding: 1rem;
            color: $color-primary;
            font-size: 2.4rem;
            font-weight: bold;
        }

        &-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-available-apartments {
            font-size: 1.2rem;
            display: flex;
            align-items: center;
            color: $color-grey-6;
            padding: 0 1rem;

            b {
                font-size: 1.6rem;
                padding-right: 0.5rem;
            }
        }

        &-change-view-button {
            display: flex;
            align-items: center;
            color: $color-grey-6;
            cursor: pointer;
            padding: 0 1rem;

            &-icon {
                @include ff-icon;
                font-size: 2rem;
                margin-right: 0.3rem;
            }

            &-label {
                font-size: 1.2rem;
                text-transform: uppercase;
            }
        }

        &-filters {
            box-shadow: 0 0 1rem 2px rgba(black, 0.2);
            // border-radius: 0.5rem;
            padding: 1rem;
            margin: 1rem 0 0;
        }
    }

    &__viewer {
        flex: 0 1 (4/3) * 100vh;
        display: block;
        overflow: hidden;
    }
}
