@import "../scss/variables";

.start {
    height: 100vh;
    width: 100%;
    display: flex;

    &__logo {
        height: 7rem;
        left: 1rem;
        top: 1rem;
        position: absolute;
        z-index: 10;
    }

    &__video {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        &-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__fake-left {
        height: 100vh;
        flex: 0 1 (4/3) * 100vh;
    }

    &__nav {
        flex: 1 0 30rem;
        // width: 30%;
        // max-width: 50rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        right: 0;
        top: 0;
        background-color: rgba($color-white, 0.9);
        box-shadow: 0 0 3rem 0px rgba(black, 0.5);

        &-content {
            display: flex;
            max-width: 50rem;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
            height: 100%;
        }

        &-title {
            padding: 2rem 3rem;
            font-size: 3rem;
            text-transform: uppercase;
            @include ff-title;

            @media (min-width: $dim-breakpoint-xlg) {
                padding: 5rem;
                font-size: 5rem;
            }

            &-small {
                font-size: 2rem;

                @media (min-width: $dim-breakpoint-xlg) {
                    font-size: 3rem;
                }
            }
        }

        &-caption {
            text-align: center;
            font-size: 3rem;
            padding: 2rem 3rem;

            @media (min-width: $dim-breakpoint-xlg) {
                font-size: 5rem;
                padding: 5rem;
            }
        }

        &-logo {
            display: flex;
            justify-content: center;
            padding: 5rem 0;

            &-img {
                width: 40%;
                max-width: 20rem;
            }
        }

        &-links {
            flex: 1 0 10rem;
            display: flex;
            flex-direction: column;
            padding: 2rem 3rem;
            justify-content: center;

            @media (min-width: $dim-breakpoint-xlg) {
                padding: 5rem;
            }
        }

        &-link {
            width: 100%;
            background-color: $color-grey-1;
            padding: 1rem 1.5rem;
            font-size: 1.6rem;
            margin-bottom: 2rem;
            border-radius: 1.5rem;
            text-align: center;
            text-decoration: none;
            color: $color-grey-7;
            // font-weight: bold;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            box-shadow: 0 0 1rem 0 $color-grey-5;
            text-transform: uppercase;

            @media (min-width: $dim-breakpoint-xlg) {
                padding: 1.5rem;
            }

            &:hover {
                background-color: $color-primary;
                color: $color-white;
            }
        }

        &-disclaimer {
            padding: 1rem;
            text-align: center;
            font-size: 1.2rem;
            color: $color-grey-6;

            @media (min-width: $dim-breakpoint-xlg) {
            font-size: 1.6rem;
            padding: 2rem;
            }
        }
    }
}
