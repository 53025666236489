@import "../scss/variables";

.field-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 2rem 0.5rem 0;

    &__label {
        font-size: 1.2rem;
        color: $color-grey-7;
        text-transform: uppercase;
        // font-weight: bold;
        padding: 0.5rem 0;
    }
}
