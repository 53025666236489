@import "../scss/variables";

.viewer-3d {
    display: flex;
    height: 100%;
    // flex: 0 1 70%;
    overflow: hidden;
    position: relative;
    justify-content: center;

    &__logo {
        position: absolute;
        left: 1rem;
        top: 1rem;
        height: 7rem;
        z-index: 20;
    }

    &__container {
        display: block;
        height: 100%;
        width: auto;
        position: relative;
    }

    &__frames {
        display: block;
        height: 100%;
        position: absolute;
        transition: transform 0.2s ease-in-out;

        &-group {
            display: block;
            position: relative;
            height: 100%;
            top: 0;
            left: 0;
            transition: opacity 0.8s ease-in-out;

            &.night {
                position: absolute;
                opacity: 0;
            }
        }
    }

    &__frame-img {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;

        &:first-child {
            position: relative;
        }
    }

    &__clickable {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        touch-action: none;
        z-index: 20;
    }

    &__elements {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__element {
        fill: rgba($color-secondary, 0.5);
        stroke: rgba($color-primary, 1);
        stroke-width: 2.4;
        // z-index: 5;
        transition: all 0.1s ease-in-out;
        cursor: pointer;

        &:hover {
            fill: rgba($color-primary, 0.8);
            stroke: rgba($color-primary, 1);
        }

        &.is-reserved {
            fill: rgba(orange, 0.3);
            stroke: rgba(orange, 1);
        }
        &.is-sold {
            fill: rgba(red, 0.3);
            stroke: rgba(red, 1);
        }

        &.is-hover {
            fill: rgba($color-primary, 0.8);
            stroke: rgba($color-primary, 1);
        }

        &.is-highlight {
            fill: rgba($color-highlight, 0.8);
            stroke: rgba($color-highlight, 1);
        }
    }

    &__buttons {
        width: 100%;
        position: absolute;
        top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 22;
    }

    &__controls {
        width: 100%;
        position: absolute;
        bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 21;
    }

    &__support {
        position: absolute;
        right: 2rem;
        bottom: 2rem;
        width: 4rem;
        height: 4rem;
        background-color: $color-white;
        box-shadow: 0 0 1rem 0 rgba(black, 0.3);
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.6rem;
        z-index: 22;
    }

    &__orientation {
        right: 12rem;
        position: absolute;
        height: 4rem;
        width: 4rem;
        z-index: 20;
        top: 2rem;

        &-circle {
            width: 4rem;
            height: 4rem;

            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            position: relative;

            &-label {
                width: 100%;
                height: 100%;
                background-color: $color-grey-1;
                box-shadow: 0 0 1rem 0 rgba(black, 0.3);
                border-radius: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 1rem;
                height: 1rem;
                background-color: $color-primary;
                z-index: -1;
                left: 1.5rem;
                top: -0.4rem;
                transform: rotate(45deg);
                box-shadow: 0 0 0.5rem 0 $color-primary;
            }
        }
    }

    &__day-night {
        position: absolute;
        right: 2rem;
        top: 2rem;
        z-index: 23;
        width: 8rem;
        height: 4rem;
        // background-color: $color-grey-1;
        // border-radius: 2rem;
        display: flex;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            width: 4rem;
            height: 4rem;
            left: 0;
            background-color: $color-primary;
            box-shadow: 0 0 1rem 0 $color-primary;
            z-index: 1;
            border-radius: 2rem;
            transition: all 0.2s ease-in-out;
        }

        &::after {
            content: "";
            width: 8rem;
            height: 3.2rem;
            position: absolute;
            background-color: $color-grey-1;
            top: 0.4rem;
            border-radius: 1.6rem;
            box-shadow: 0 0 1rem 0 rgba(black, 0.3);
        }

        &-icon {
            @include ff-icon;
            width: 4rem;
            height: 4rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            color: $color-grey-7;

            &.left {
                color: $color-white;
            }
        }
    }

    &__visibility {
        position: absolute;
        right: 2rem;
        top: 7rem;
        z-index: 23;
        width: 8rem;
        height: 4rem;
        // background-color: $color-grey-1;
        // border-radius: 2rem;
        display: flex;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            width: 4rem;
            height: 4rem;
            left: 0;
            background-color: $color-primary;
            box-shadow: 0 0 1rem 0 $color-primary;
            z-index: 1;
            border-radius: 2rem;
            transition: all 0.2s ease-in-out;
        }

        &::after {
            content: "";
            width: 8rem;
            height: 3.2rem;
            position: absolute;
            background-color: $color-grey-1;
            top: 0.4rem;
            border-radius: 1.6rem;
            box-shadow: 0 0 1rem 0 rgba(black, 0.3);
        }

        &-icon {
            @include ff-icon;
            width: 4rem;
            height: 4rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            color: $color-grey-7;

            &.left {
                color: $color-white;
            }
        }
    }

    &.is-night {
        .viewer-3d {
            &__day-night {
                &::before {
                    left: 4rem;
                }

                &-icon {
                    &.left {
                        color: $color-grey-7;
                    }

                    &.right {
                        color: $color-white;
                    }
                }
            }

            &__frames {
                &-group {
                    &.night {
                        // visibility: visible;
                        opacity: 1;
                        z-index: 1;
                    }

                    &.day {
                        // visibility: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }

    &.is-element-visibility-off {
        .viewer-3d {
            &__visibility {
                &::before {
                    left: 4rem;
                }

                &-icon {
                    &.left {
                        color: $color-grey-7;
                    }

                    &.right {
                        color: $color-white;
                    }
                }
            }
        }
    }
}
