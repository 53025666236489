@import "../scss/variables";

.apartment-list {
    width: 100%;
    max-width: $dim-breakpoint-lg;
    margin-top: 2rem;
    display: flex;
    flex: 1 0 20rem;
    overflow: auto;
    padding: 0 1rem;
    // border-top: 1px solid $color-grey-1;

    &.with-table {
        padding: 0 2rem;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;

        thead {
            th {
                text-align: left;
                font-size: 1.2rem;
                background-color: $color-white;
                color: $color-grey-7;
                padding: 1rem;
                border-bottom: 2px solid $color-grey-3;
                text-align: center;
                text-transform: uppercase;
            }
        }

        tbody {
            td {
                font-size: 1.2rem;
                padding: 1rem;
                text-align: center;
            }

            tr {
                cursor: pointer;
                &:nth-child(2n) {
                    background-color: $color-grey-1;
                }

                &:hover,
                &.is-hover {
                    background-color: $color-primary;
                }
            }
        }
    }

    &__cards {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    &__card {
        width: 100%;
        padding: 1rem;
        display: flex;
        cursor: pointer;
        // min-width: 25rem;
        // max-width: 50%;

        @media (min-width: 2160px) {
            width: 50%;
        }

        &-content {
            // border: 1px solid $color-grey-1;
            padding: 1rem;
            height: 100%;
            box-shadow: 0 0 0.5rem 0 $color-grey-3;
        }

        &-title {
            font-size: 2.4rem;
            color: $color-primary;
            font-weight: bold;
            padding: 0 0 1rem 0;
        }

        &-availability {
            font-size: 1.6rem;
            color: $color-grey-6;
            font-weight: bold;
            padding: 0 0 1rem 0;
        }

        &-layout {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 50%;
            }
        }

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &-prop {
            display: flex;
            flex-direction: column;
            flex: 1 0 50%;
            padding: 0.5rem 0;

            &-label {
                font-size: 1.2rem;
                text-transform: uppercase;
                color: $color-grey-7;
            }

            &-value {
                font-size: 1.6rem;
                font-weight: bold;
                color: $color-grey-7;
            }
        }

        &.is-hover {
            .apartment-list {
                &__card {
                    &-content {
                        box-shadow: 0 0 1rem 0 $color-primary;
                    }
                }
            }
        }
    }
}
