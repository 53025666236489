@import "../scss/variables";

.nav {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: $color-white;
    z-index: 100;
    // border-left: 1px solid $color-primary;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__container {
        z-index: 100;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 70rem;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: relative;
    }

    &__close {
        position: absolute;
        @include ff-icon;

        top: 2rem;
        right: 2rem;
        width: 6rem;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 4rem;
        color: $color-grey-6;
        cursor: pointer;
    }

    // &::before {
    //     content: "";
    //     position: fixed;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     background-color: rgba($color-white, 0.8);
    //     z-index: 99;
    // }

    &__logo {
        display: flex;
        justify-content: center;
        padding: 5rem 0;

        &-img {
            width: 40%;
            cursor: pointer;
            max-width: 20rem;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        padding: 5rem;
        width: 100%;
        max-width: 50rem;
    }

    &__link {
        width: 100%;
        background-color: $color-grey-1;
        padding: 1.5rem;
        font-size: 1.6rem;
        margin-bottom: 2rem;
        border-radius: 3rem;
        text-align: center;
        text-decoration: none;
        color: $color-grey-7;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}
