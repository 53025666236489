@import "../scss/variables";

.config-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__title {
        padding: 2rem;
        font-size: 2rem;
        color: $color-secondary;
        font-weight: bold;
    }
}
